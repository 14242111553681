/* ======================================= */
/* =============== GENERAL =============== */
/* ======================================= */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
[role="button"] {
  cursor: pointer;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  display: table;
  content: " ";
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}

@font-face {
  font-family: "Brown-Light";
  src: url("https://static-cache.cocunat.workers.dev/?image=https%3A%2F%2Fglobal.cocunat.com%2Ffonts%2Flineto-brown-light.woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Brown-Bold";
  src: url("https://static-cache.cocunat.workers.dev/?image=https%3A%2F%2Fglobal.cocunat.com%2Ffonts%2Flineto-brown-bold.woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Brown-Regular";
  src: url("https://static-cache.cocunat.workers.dev/?image=https%3A%2F%2Fglobal.cocunat.com%2Ffonts%2Flineto-brown-pro-regular.woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.font-brown-light {
  font-family: "Brown-Light";
}

.font-brown-regular {
  font-family: "Brown-Regular" !important;
}

.font-brown-bold {
  font-family: "Brown-Bold";
}

h1, h2, h3, h4, h5, h6, strong{
  font-family: 'Brown-Bold';
}

body{
  font-family: 'Brown-Light';
  padding-top: 52px;
}

.custom-select{
  background: #fff;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-select-value{
  cursor: pointer;
  padding: 0px 10px;
  position: relative;
}

.custom-select-value i{
  position: absolute;
  top: 0px;
  right: 10px;
}

.custom-select span{
  
}

.custom-select img{
  margin-right: 7px;
}

.custom-select ul{
  list-style-type: none;
  background: #fff;
  line-height: 35px;
  padding: 0;
  margin: 0px;
  margin-top: -2px;
  display: none;
  z-index: 2;
  position: relative;
}

.custom-select:hover > ul {
  display: block;
}

.custom-select li{
  border-top: 1px solid #ccc;
  cursor: pointer;
  padding: 0px 10px;
}

button.primary{
  background-color: red;
  border-color: transparent;
  color: #fff !important;
}

button.regular{
  height: 42px;
  padding: 0px 30px;
}

button.big{
  height: 54px;
  padding: 0px 30px;
  text-transform: uppercase;
}

button.wide{
  width: 100%;
}

header{
  height: 52px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background: #fff;
  z-index: 999;
}

header .container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  position: relative;
}

#cart-header-number{
  position: absolute;
  top: 20px;
  width: 23px;
  height: 23px;
  display: block;
  background: red;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 23px;
  right: -10px;
}

.container-small{
  width: 100%;
  max-width: 550px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.input-group{
  margin: 5px 0px;
}

.input-group input,
.input-group select{
  display: block;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 4px;
  height: 45px;
  color: #737373;
  line-height: 45px;
  padding: 0px 10px;
  width: 100%;
}

.alert{
  padding: 15px;
  border: 1px solid #2196f3;
  background: #7ebdf8;
  margin: 20px 0px;
  border-radius: 5px;
}

.alert-danger{
  border-color: red;
  background: #f44336;
  color: #fff
}

a{
  color: red;
}

/* ======================================= */
/* ================ INTRO ================ */
/* ======================================= */


body.intro{
  background: #ffc2c3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

body.intro .container{
  text-align: center;
}

body.intro h1{
  max-width: 500px;
  margin: auto;
}
body.intro h1 i{
  font-style: italic;
  font-family: 'Brown-Regular';
}

/* ======================================= */
/* =============== SEARCH ================ */
/* ======================================= */

#hero{
  background: url("https://resizing.cocunat.com/r/?quality=100&fit=contain&image=https%3A%2F%2Fglobal.cocunat.com%2Fstore-cover-1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffbcbf;
  background-size: contain;
  height: 200px;
}


#category-bar{
  padding: 30px 0px 10px 0px;
}

#category-bar a.active span{
  font-family: 'Brown-Bold';
}

#category-bar .container{

}

#search-bar{
  padding: 0px 0px 30px 0px;
  border-bottom: 1px solid #ececec;
}

#search-bar .input{
  position: relative
}

#search-bar .input input{
  width: 100%;
  padding: 0px 45px 0px 10px;
  height: 45px;
  border: 1px solid #AAA;
}

#search-bar .input button{
  position: absolute;
  top: 0px;
  right: 10px;
  background: #ffbcbf;
  font-size: 24px;
  color: #fff;
  height: 45px;
  padding: 0px 15px;
  line-height: 10px;
  border: 1px solid #AAA;
}

#category-bar a{
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #000;
  text-decoration: none;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  height: 125px;
}

#category-bar a img{
  margin-bottom: 10px;
  height: 65px;
  width: 65px;
}

.product-item{
  text-align: center;
  margin-bottom: 30px;
}

.product-item img{
  display: block;
  margin: auto;
  width: auto;
}

.product-title{
  overflow: visible;
  height: 60px;
  margin: 10px 0px;
}

.product-title p{
  margin: 0px
}

.promo-sticker-product-loop{
  height: auto !important;
  width: 65px !important;
  position: absolute;
  top: 5px;
  left: 5px;
}

.product-item button.primary{
  display: block;
  width: 100%;
  margin-top: 15px;
}

.product-list{
  padding-top: 30px;
  padding-bottom: 100px;
}

.product-list a{
  color: #000;
  text-decoration: none;
}

/* ======================================= */
/* ================ CART ================= */
/* ======================================= */

.orderline-row{
  display: flex;
  padding:20px 0px;
  border-bottom: 1px #ddd solid;
}

.orderline-row .inner-orderline-row{
    width:33%;
    text-align:center;
}

.orderline-row img{
  height: 100px;
}

.orderline-row p{
    font-family:"Brown-Bold";
    text-transform: uppercase;
    font-size:14px;
}

.orderline-row .orderline-name{
    text-align:left;
}

.orderline-row .controls{
  border: 1px solid #ddd;
  display: inline-flex;
  font-size: 12px;
  line-height: 27px;
  font-weight: bold;
  width: 70px;
}

.orderline-present .controls{
  justify-content: center;
}

.orderline-row .controls button{
    background-color: #fff;
    color: black ;
    border: 0px;
    font-size: 21px ;
    padding: 0px 10px 7px 10px;
    height: 27px;
    line-height: 23px;
}

.orderline-row .controls .delete-orderline{
    margin-left: 20px;
    font-size: 12px;
    color: #757575;
    letter-spacing: 0.55px;
    cursor: pointer;
}
.orderline-row .price{
    text-align:right;
}

.cart-title-header{
  padding: 10px;
  border-color: rgb(239, 239, 239);
  border-style: solid;
  border-width: 1px 0px;
}

.cart-title-header p{
  margin: 0px;
  text-align: center;
}

.totals-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #111;
  border-left: 0px;
  border-right: 0px;
  cursor: pointer;
}

.totals-content p{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo-code-input{position: relative;display:flex;margin-bottom:15px;}
.promo-code-input input{width: 100%;
    padding: 16px;
    border: 1px solid #dbdbdb;
    width:75%;
    display:inline-block
}
.promo-code-input button{
    width: 25%;
    margin-left: 5px;
    text-transform: uppercase !important;
    font-size: 14px;
    height: auto;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    color: #363636;
    cursor: pointer;
    justify-content: center;
}

.promo-cart-banner{padding: 10px;
    text-align: center;
    border: 2px   solid #fab5ab;
    border-radius: 5px;
    margin: 25px 0px;
}

.promo-cart-banner .cart-image{    
    width: 40px;
    height: auto;
    margin-right:0px;
    float:left;
    text-align:center;
}

.promo-cart-banner .cart-image img{max-width:100%;height:auto;}
/* ======================================= */
/* ============== CHECKOUT =============== */
/* ======================================= */

.checkout-preview-address{
  padding: 16px;
  border: 1px solid #efefef;
  border-radius: 6px;
  margin: 45px 0px 20px 0px;
}

.checkout-preview-address p{
  margin: 0px;
  color: #737373;
}


/* ======================================= */
/* ============== PRODUCT =============== */
/* ======================================= */

#product-single #title h1{
    font-weight: bold;
    font-family: 'Brown-Regular';
    font-size: 36px;
    text-align: center;
    line-height: 34px;
    margin:0px;
    padding:0px;
}

#product-single #title h2{
    text-transform: initial;
    display: block;
    font-family: "Brown-Regular";
    margin: 5px 0px 7px 0px;
    font-size: 22px;
    color: #000;
    font-weight: 100;
    line-height: 28px;
    text-align:center;
}
#product-single #title small{
    text-transform: initial;
    display: block;
    font-family: "Brown-Regular";
    margin: 5px 0px 7px 0px;
    font-size: 18px;
    color: #000;
    font-weight: 100;
    line-height: 28px;
    text-align:center;
}
#product-single s{
    background: url(https://global.cocunat.com/stroke.png);
    text-decoration: none;
    background-position: center 3px;
    background-size: 4px 17px ;
    background-repeat: repeat-x;
}
#product-single {
    font-size: 18px;
    text-align: left;
}

#product-single  ul {
    list-style-type: none;
    padding-left: 25px;
    margin-left:0px;
}

#product-single #summary li {
    text-align: left;
    margin-bottom: 5px;
    display: flex;
}

#product-single #summary ul li:before {
    content: "";
    background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=36&height=34&image=https://global.cocunat.com/web/list-icon-cocunat.png);
    background-size: 11px 11px;
    background-repeat: no-repeat;
    margin-right: 7px;
    margin-top: 7px;
    min-width: 16px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 50%;
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 23px;
}

#product-single #summary  li p {
    display: inline-block;
    margin: 0px;
}

#product-single .packaging-item{
    margin-bottom: 15px;
    text-align: left;
    min-height: 36px;
    align-items: center;
    display: flex;
}

#product-single .packaging-item img{
    margin-right: 10px;
    width:36px;
    height:auto;
}

#product-single .packaging-item span{
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
    width: 90px;
    flex: 1;
}

#product-single #description h3,#product-single .heart-title{
    font-family: 'Brown-Light';
    font-weight: bold;
    font-size: 27px;
    text-align: center;
    padding: 0px 15px;
    line-height: 33px;
    margin-bottom: 5px;
    color: #ff8172;
}

#product-single .incii-img{
    padding:0px;
    margin:0px

}
#product-single .incii-img img{
    width:100%;
    height:auto;
}

#product-single #how-to-use{
    padding:30px 0px;
    background-color: #5bc6d0;
    color:white;
    text-align:center;

}

#product-single #ideal-for {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ideal-for-text{
    padding: 25px 20px;
    background: rgb(255, 255, 255);
    margin-top: -100px;
}

@media (min-width:767px){
.product-single{
    width: 100%;
    max-width: 550px;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
}
}

/* ======================================= */
/* =============== OTHERS ================ */
/* ======================================= */


@media (min-width:767px){
  #hero{
    height: 300px;
  }
  #category-bar a{
    font-size: 16px;
    height: 125px;
  }
  #category-bar a img{
    height: 75px;
    width: 75px;
  }
}


.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}

.skeleton-line {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}
.skeleton-box::after,
.skeleton-line::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
          animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* PRICE */

.price-product{
    line-height: 24px;
    white-space: nowrap;
    font-family: 'Brown-Regular';
}

.price-product .strike-price{
    text-decoration: line-through;
    font-size: 0.85em;
    color: red !important;
    font-weight: 400;
    margin-left: 5px;
}

/* CIRCLE */

.radio-button{
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding:5px 0px;
}

.circle,
.circle-o{
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 100%;
}

.circle{
  border: 1px solid #444;
}

.circle-o{
  border: 1px solid red;
}

.circle-o::after{
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: red;
  display: block;
}


/* SLIDER */

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}
